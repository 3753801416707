<template>
    <div>
        <Form label-position="left" :label-width="120">
            <FormItem>
                <span slot="label" class="validate">所属社区:</span>
                <Tag type="dot" style="margin-right: 20px" color="primary" closable @on-close="openSelect" v-if="!showSelect && editRow.authOrgName">{{ editRow.authOrgName }}</Tag>
                <Cascader style="width: 300px" v-if="showSelect" v-model="dataScopeList" :data="publishList" :load-data="loadCommunity" change-on-select @on-change="changeCascader"></Cascader>
                <Button type="primary" @click="openSelect" v-if="!showSelect">{{ editRow.authOrgName && editRow.authOrgName != "" ? "切换社区" : "选择社区" }}</Button>
            </FormItem>
            <FormItem>
                <span slot="label" class="validate">姓名:</span>
                <Input placeholder="请输入您的真实姓名" :maxlength="20" v-model="editRow.userName" style="width: 300px" />
            </FormItem>
            <FormItem>
                <span slot="label" class="validate">人员类型:</span>
                <Select style="width: 300px; margin-right: 10px" v-model="editRow.userType" placeholder="请选择人员类型" @on-change="changeUserType">
                    <Option v-for="(item, index) in userType" :key="index" :value="item.dictKey">{{ item.dictValue }}</Option>
                </Select>
            </FormItem>
            <FormItem>
                <span slot="label" class="validate">证件类型:</span>
                <Select style="width: 300px; margin-right: 10px" v-model="editRow.idNumType" placeholder="请选择证件类型" >
                    <Option v-for="(item, index) in idNumType" :key="index" :value="item.dictKey">{{ item.dictValue }}</Option>
                </Select>
            </FormItem>
            <FormItem>
                <span slot="label" class="validate">证件号码:</span>
                <Input placeholder="请输入您的证件号码" :maxlength="20" v-model="editRow.idNum" style="width: 300px" />
            </FormItem>
            <FormItem>
                <span slot="label" class="validate">手机号码:</span>
                <Input placeholder="请输入您的手机号码" :maxlength="20" v-model="editRow.mobile" style="width: 300px" />
            </FormItem>
            <FormItem v-if="editRow.idType != 1">
                <span slot="label" class="validate">性别:</span>
                <RadioGroup v-model="editRow.sex">
                    <Radio label="1">男</Radio>
                    <Radio label="2">女</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem v-if="editRow.idType != 1">
                <span slot="label" class="validate">出生日期:</span>
                <DatePicker v-model="editRow.birthday" type="date" placeholder="请选择出生年月日" style="width: 300px" @on-change="changeDate"></DatePicker>
            </FormItem>
            <FormItem>
                <span slot="label" class="validate">国籍:</span>
                <Input placeholder="请输入您的国籍" :maxlength="20" v-model="editRow.nationality" style="width: 300px" />
            </FormItem>
            <FormItem>
                <span slot="label" class="validate">居住时长:</span>
                <Select style="width: 300px; margin-right: 10px" v-model="editRow.liveType" placeholder="请选择人员类型" @on-change="changePerson">
                    <Option v-for="(item, index) in liveType" :key="index" :value="item.dictKey">{{ item.dictValue }}</Option>
                </Select>
            </FormItem>
            <FormItem>
                <span slot="label" class="validate">现住地址:</span>
                <Input placeholder="请选择您的现住地址" search enter-button="选择" @on-search="searchLocal" :maxlength="20" v-model="editRow.address" @on-change="changeAddress" />
            </FormItem>
        </Form>
        <LiefengModal
          title="选择现住地址"
          :value="addrStatus"
          @input="addrStatusFn"
          :fullscreen="false"
          width="30%"
          class="edit"
        
        >
          <template v-slot:contentarea>
              <Tree v-if="showTree" :code="treeCode" :name="treeName" @handleTree="handleTree"></Tree>
          </template>
          <template v-slot:toolsbar>
           <Button style="margin: 0 5px 0 13px" type="warning" @click="addrTip=true;addrStatusFn(false);">取消</Button>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="addrSave">保存</Button>
        </template>
      </LiefengModal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
import Tree from "./tree"
export default {
    
    props: {
        editRow: {
            type: Object,
            default: () => {
                return {}
            },
        },
        id:""
        // dataScopeList:{
        //     type:Array,
        //     default:()=>{
        //         return []
        //     }
        // }
    },
    //import引入的组件需要注入到对象中才能使用
    components: { LiefengModal, Tree },
    data() {
        //这里存放数据
        return {
            // 人员类型
            userType:[],
            liveType:[],
            idNumType: [],
            idNumTypeTow:[],


            personalOptions: [],
            categoryOptions: [],
            houseOptions: [],
            showSelect: false,
            // 发布范围列表
            publishList: [],
            dataScopeList: [],
            dataNameList: [],
            addrStatus: false,
            addrTip: false,
            showTree: false,
            treeCode: "",
            treeName: "",
            selectTree: [],
            address: "",
            treeData: [],
        }
    },
    //方法集合
    methods: {
        // 只要地址一改变，我就清掉houseCode
        changeAddress() {
            this.editRow.houseCode = ""
        },
        // 点击了树节点的东西
        handleTree(val, treeData) {
            this.selectTree = val
            this.treeData = []
            this.treeData = treeData
            this.address = ""
            this.getCurrentTitle(this.selectTree[0].id, treeData)
        },

        //递归面包屑
        getCurrentTitle(id, treeData) {
            for (let k in treeData) {
                if (treeData[k].id == id) {
                    this.address = (treeData[k].name || treeData[k].orgName) + this.address
                    if (!treeData[k].parentId) return
                    this.getCurrentTitle(treeData[k].parentId, this.treeData)
                } else if (treeData[k].children && treeData[k].children.length > 0) {
                    this.getCurrentTitle(id, treeData[k].children)
                }
            }
        },
        // 取消按钮
        addrSave() {
            if (this.selectTree && this.selectTree.length == 0) {
                if (this.dataScopeList.length == 0) {
                    this.$Message.warning({
                        content: "请选择现住地址",
                        background: true,
                    })
                    return
                }
            } else {
                if (this.selectTree[0].regionType != "0" && this.selectTree[0].regionType != "4") {
                    this.$Message.warning({
                        content: "现住地址请选择到房号",
                        background: true,
                    })
                    return
                } else {
                    this.editRow.address = this.address
                    this.editRow.houseCode = this.selectTree[0].code
                    this.addrStatus = false
                    this.showTree = false
                    this.addrTip = false
                }
            }
        },
        // 保存按钮
        addrStatusFn(status) {
            if (!status && this.addrTip) {
                this.addrStatus = status
                this.showTree = false
                this.addrTip = false
            } else {
                this.addrStatus = status
                this.showTree = false
                this.addrTip = false
            }
        },
        // 点击了选择现住地址的选择按钮事件
        searchLocal() {
            if (this.editRow.authOrgCode == "") {
                if (this.dataScopeList.length == 0) {
                    this.$Message.warning({
                        content: "请先选择所属社区",
                        background: true,
                    })
                    return
                } else if (this.dataScopeList.length != 3) {
                    this.$Message.warning({
                        content: "所属社区请选择到社区",
                        background: true,
                    })
                    return
                } else {
                    let code = this.dataScopeList[this.dataScopeList.length - 1].split("-")
                    this.treeCode = code[code.length - 1]
                    // this.editRow.authOrgCode = this.treeCode
                    this.treeName = this.dataNameList[this.dataScopeList.length - 1].label
                    // this.editRow.orgName = this.treeName
                    console.log(this.treeName)
                    this.addrStatus = true
                    this.$nextTick(() => {
                        this.showTree = true
                    })
                }
            } else {
                this.treeCode = this.editRow.authOrgCode
                this.treeName = this.editRow.orgName
                console.log(this.editRow.authOrgCode)
                console.log(this.treeCode)
                this.addrStatus = true
                this.$nextTick(() => {
                    this.showTree = true
                })
                
            }
        },
        // 改变日期事件
        changeDate(val) {
            this.editRow.birthday = val
        },
        // 选择级联事件
        changeCascader(val, value) {
            this.dataNameList = []
            this.dataNameList = value
            this.$emit("changeCascader", value)
        },
        // 点击关闭标签事件
        // closeTag(){
        //   this.editRow.orgName = ''
        //   this.editRow.authOrgCode = ''
        //   this.showSelect =true
        // },
        // 点击切换社区按钮
        openSelect() {
            this.showSelect = true
            this.editRow.orgName = ""
            this.editRow.authOrgCode = ""
            this.editRow.address = ''
            // 获取广州市的区
            this.getPulishData(4401, "publishList")
        },
        // 改变证件类型下拉框
        changeUserType(val){
              if( val == 1 || val == 2 || val == 3 || val == 4 || val == 6 )
              this.editRow.nationality = '中国'
              else
              this.editRow.nationality =''
              if( val == 1 || val == 6){
                this.idNumType = this.idNumTypeTow.filter(item => item.dictKey == 4)
                this.editRow.idNumType = 4
              }else if( val == 2 || val == 3 ){
                this.idNumType = this.idNumTypeTow.filter(item => item.dictKey == 1 || item.dictKey == 3)
                this.editRow.idNumType = 1
              }else if( val == 4 ){
                this.idNumType = this.idNumTypeTow.filter(item => item.dictKey == 2 || item.dictKey == 3)
                this.editRow.idNumType = 2
              }else if( val == 5 ){
                this.idNumType = this.idNumTypeTow.filter(item => item.dictKey == 3)
                this.editRow.idNumType = 3
              }
              
              console.log(this.editRow)
        },
        // 字典接口
        getOption(data, model) {
            this.$get("/old/api/common/sys/findDictByDictType", {
                dictType: data,
            }).then(res => {
                this[model] = res.dataList
            })
        },
        // 获取区
        getPulishData(code, list) {
            this.$get("/old/api/pc/staff/selectCascadeDataScope", {
                orgCode: code,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this[list] = res.dataList.map(item => {
                        return {
                            value: item.orgPath,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                            loading: false,
                            children: [],
                        }
                    })
                }
            })
        },
        // 动态加载街道/社区
        loadCommunity(item, callback) {
            item.loading = true
            let arrCode = item.value.split("-")
            this.$get("/old/api/pc/staff/selectCascadeDataScope", {
                orgCode: arrCode[arrCode.length - 1],
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    if (!item.index) {
                        item.children = res.dataList.map(item => {
                            return {
                                value: item.orgPath,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                loading: false,
                                children: [],
                                index: 1,
                            }
                        })
                    } else {
                        item.children = res.dataList.map(item => {
                            return {
                                value: item.orgPath,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                index: 1,
                            }
                        })
                    }
                    item.loading = false
                    callback()
                }
            })
        },
        formatterData(data, id) {
            let parentId = ""
            if (data && data.length != 0) {
                data.map(item => {
                    if (parentId == "") {
                        if (item.id == id) {
                            this.address = item.name + this.address
                            parentId = item.parentId
                            this.formatterData(item.children, parentId)
                        } else {
                            if (item.children && item.children.length != 0) {
                                this.formatterData(item.children, id)
                            }
                        }
                    } else {
                        if (item.parentId == id) {
                            this.address = item.name + this.address
                        } else {
                            if (item.children && item.children.length != 0) {
                                this.formatterData(item.children, parentId)
                            }
                        }
                    }

                    // if(item.children && item.children.length !=0){
                    //   this.formatterData(item.children,id)
                    // }else{
                    //   console.log('id',id);
                    //   console.log('parentId',item.parentId);
                    // }
                })
            }
            return this.address
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        // this.getOption("CREDENTIALS_TYPE", "categoryOptions")
        // this.getOption("PERSONAL_TYPE", "personalOptions")
        // this.getOption("ONLINE_HOME_USER_TYPE", "houseOptions")
    },
    watch: {
        editRow: {
            handler(val, newVal) {
                if( this.editRow.userType == 1 || this.editRow.userType == 6){
                        this.idNumType = this.idNumTypeTow.filter(item => item.dictKey == 4)
                    }else if( this.editRow.userType == 2 || this.editRow.userType == 3 ){
                        console.log(this.editRow.userType)
                        this.idNumType = this.idNumTypeTow.filter(item => item.dictKey == 1 || item.dictKey == 3)
                    }else if( this.editRow.userType == 4 ){
                        console.log(this.editRow.userType)
                        this.idNumType = this.idNumTypeTow.filter(item => item.dictKey == 2 || item.dictKey == 3)
                    }else if( this.editRow.userType == 5 ){
                        console.log(this.editRow.userType)
                        this.idNumType = this.idNumTypeTow.filter(item => item.dictKey == 3)
                    }
                this.showSelect = false
                this.treeCode = val.orgCode
                this.treeName = val.orgName
                this.address = ""
            },
        },
        dataScopeList:{
            handler(val){
                console.log(val)
                if(val.length == 3 ){
                    let arr = val[2].split('-')
                    console.log(arr)
                    if(arr.length == 5){
                        console.log(this.editRow)
                        this.editRow.authOrgCode = arr[4]
                        console.log(this.editRow.authOrgCode)
                        
                    }
                }else{
                    this.editRow.authOrgCode = ''
                }
            
            }
        },
        id:{
            handler(val,newVal){
                console.log(newVal)
                    console.log(this.editRow)
                //  this.$nextTick( () => {
                    
                    console.log(this.idNumType)
                console.log(this.idNumTypeTow)
                // })
            }
        }
    },
}
</script>
<style lang='less' scoped>
//编写css
</style>